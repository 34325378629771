import EmailValidator from 'email-validator';

export type ValidationFieldType = 'name' | 'address';

export function basicEmailValidation(text = `Enter a valid email address e.g. example@email.com`) {
    return (value) => {
        if (!EmailValidator.validate(value)) {
            return text;
        }
    };
}

export function basicPresenceValidation(text = `This is a required field`) {
    return (value) => {
        return !value ? text : undefined;
    };
}

export function basicNamePresenceValidationKlarna(defaultErrorText = `This is a required field`) {
    return (value) => {
        // Is the field empty and is required
        if (!value) return defaultErrorText;
        // Validate text for Klarna's requirements
        return validateTextAgainstKlarnaRules(value, 'name') ?? undefined;
    };
}

export function basicAddressPresenceValidationKlarna(defaultErrorText = `This is a required field`) {
    return (value) => {
        // Is the field empty and is required
        if (!value) return defaultErrorText;
        // Validate text for Klarna's requirements
        return validateTextAgainstKlarnaRules(value, 'address') ?? undefined;
    };
}

/**
 * Validate the postcode Eircode regex
 * @param value
 */
const eircodeValidation = (value: string) => {
    // This regular expression checks for a valid Irish Eircode.
    // Irish postcodes are in the format: A12 B345 or D6W 1234
    // The first character can be any letter from A-Za-z, followed by two more characters as a number ot letter.
    // The second part is optional and can be a space or a hyphen only.
    // The third part is any letter from A-Za-z or a number, repeated 4 times.
    const regex = new RegExp('^[A-Za-z][0-9A-Za-z]{2}[ -]?[0-9A-Za-z]{4}$', 'i');

    if (!regex.test(value)) {
        return 'Please enter a valid postcode';
    }
    return undefined;
};

export const validateEircode = (value: string) => eircodeValidation(value);

/**
 * Validate the postcode against the Eircode format
 * @param defaultErrorText
 */

export function basicPostCodePresenceValidationKlarna(defaultErrorText = `This is a required field`) {
    return (value) => {
        // Is the field empty and is required
        if (!value) return defaultErrorText;

        return validateEircode(value) ?? undefined;
    };
}

/**
 * Allows validation of fields that aren't required but still need to pass Klarna's requirements
 * @param fieldType
 */
export const basicPresenceValidationNonRequiredFields = (fieldType: ValidationFieldType) => {
    return (value) => {
        // If we have a value, validate it against Klarna requirements
        if (value) return validateTextAgainstKlarnaRules(value, fieldType);

        // Default to undefined because field isn't required
        return undefined;
    };
};

/**
 * Perform a validation of the text supplied against rules that Klarna set for data being sent to them
 *
 * @see https://docs.klarna.com/klarna-payments/in-depth-knowledge/customer-data-requirements/#eu-region-ireland
 *
 * @param valueToCheck supply text for the check to happen
 * @param fieldType klarna has different character acceptance for names or address fields
 */
export const validateTextAgainstKlarnaRules = (valueToCheck: string, fieldType: ValidationFieldType) => {
    switch (fieldType) {
        case 'address':
            return hasUnwantedCharacters(valueToCheck, fieldType)
                ? 'This address field contains disallowed characters. Please enter only alphanumeric, spaces or -\'´`",.:#&()+@ °ºᵃª_'
                : undefined;

        case 'name':
            return hasUnwantedCharacters(valueToCheck, fieldType)
                ? "This field contains disallowed characters. Please enter only alphanumeric, spaces or -'’.°ºᵃª"
                : undefined;

        default:
            return undefined;
    }
};

/**
 * Determine whether the supplied string has characters in it that aren't acceptable for Klarna
 *
 * @see https://docs.klarna.com/klarna-payments/in-depth-knowledge/customer-data-requirements/#eu-region-ireland
 * @param valueToCheck pass a string for the regex to check against
 */

export const hasUnwantedCharacters = (valueToCheck: string, fieldType: ValidationFieldType) => {
    let ALLOWED_CHARACTERS_REGEX: RegExp;
    switch (fieldType) {
        case 'address':
            ALLOWED_CHARACTERS_REGEX = /[^-'´`\",\.:;#&\/\(\)\+@ °ºᵃª_ \\a-z0-9]/gi;
            return ALLOWED_CHARACTERS_REGEX.test(valueToCheck);

        case 'name':
            ALLOWED_CHARACTERS_REGEX = /[^-'’.°ºᵃªa-z0-9 ]/gi;
            return ALLOWED_CHARACTERS_REGEX.test(valueToCheck);

        default:
            return undefined;
    }
};

export const basicPresenceValidationDeliveryMethods = () => {
    return (value) => {
        if (!value) return 'This is a required field';
        return undefined;
    };
};

export function basicMaxLengthValidation(maxLength = 10, text = `Cannot be more than ${maxLength} characters`) {
    return (value) => {
        if (!value) return undefined;
        if (typeof value === 'string' && value.length > maxLength) {
            return text;
        }
        return undefined;
    };
}

export function acmePasswordValidation(
    text = 'Enter at least 8 characters',
    invalid = 'Must include a number and a letter',
) {
    // Minimum length of this field must be equal or greater than 8 symbols. Leading and trailing spaces will be ignored.
    // Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.
    return (value) => {
        let counter = 0;
        const passwordMinLength = 8;
        const passwordMinCharacterSets = 2;
        const pass = value || ''.trim();

        if (pass.length < passwordMinLength) {
            return text;
        }

        if (pass.match(/\d+/)) {
            counter++;
        }

        if (pass.match(/[A-z]+/)) {
            counter++;
        }

        if (counter < passwordMinCharacterSets) {
            return invalid;
        }
    };
}

export const matchValidation = (password1, password2) => {
    return password1 !== password2 ? `pwMatch` : undefined;
};

const passwordValidation = (password1, password2) => {
    return acmePasswordValidation('minlength', 'validPassword')(password1) || matchValidation(password1, password2);
};

export const validatePassword = (value, values) => passwordValidation(value, values.confirmation);
export const validateConfirm = (value, values) => passwordValidation(value, values.password);

export function telephoneValidation(text = 'Enter a valid phone number', maxlength = 'Enter a maximum of 11 numbers') {
    const re = /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/;
    return (value) => {
        const result = re.test(value);

        if (!result) {
            return text;
        }

        if (!value || value.length > 11) {
            return maxlength;
        }
    };
}

/**
 * Checks that strings work for price inputs.
 */
export function isPriceDecimal(value: any): boolean {
    return /^(\d+?)(\.[\d]{1,2})?$/.test(String(value).trim());
}

function isFloat(value: any): boolean {
    return /^(\d+?)(\.[\d]+)?$/.test(String(value).trim());
}

/**
 * Accept any input and convert to a positive integer
 * @param value
 */
export function qtyRoundDown(value: any): number {
    const num = toNumber(value);
    return Math.floor(num);
}

function toNumber(value): number {
    if (isPositiveInteger(value)) {
        return parseFloat(value) || 1;
    }
    if (isFloat(value)) {
        return parseFloat(value) || 1;
    }
    if (isPriceDecimal(value)) {
        return parseFloat(value) || 1;
    }
    return 1;
}

function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
}
